import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Quote from '../components/Quote';
import Resource from '../components/Resource';
import Section from '../components/Section';

const AboutText = <h2 className="header-hero__title">ABOUT</h2>;

const AboutPage = ({ data }) => (
  <Layout title="About">
    <Hero
      mediaSource="about-hero.jpg"
      textOverlay={AboutText}
      className="mb-0"
    />
    <Section noPadding className="about-mission">
      <Container fullWidth className="bg-white">
        <Row>
          <Column size={8} className="about-mission__column-description">
            <h1 className="color-primary-blue uppercase">
              Striking a Chord with STEAM Learning
            </h1>
            <p className="large">
              The Country Music Association (CMA) and Discovery Education are
              partnering to inspire a new generation of STEAM problem-solvers
              and creative innovators with digital education resources
              spotlighting diverse careers in the Country Music industry.
              Designed to support K-12 STEAM curriculum and collaboration with
              music educators, Working in Harmony gives classrooms and
              households nationwide an inside look at how STEAM skills and
              collaboration work in concert to innovate the genre of Country
              Music.
            </p>
          </Column>
          <Column size={4} className="about-mission__column-guitar">
            <Image
              className="about-mission__column-guitar__img"
              filename={'about-graphic-guitarplayer.svg'}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section noPadding className="about-resources">
      <Container fullWidth className="about-resources__container pt-3 pb-6">
        <div className="about-resources__container__content">
          <h2 className="pb-2 color-white">
            STEAM Resources for the Classroom and Beyond
          </h2>
          <Row>
            <Column size={4}>
              <Resource
                withBorder={false}
                asCard
                img="img-about-resource-classroom.jpg"
                title="Classroom Resources"
                description="Bring to life the sights, sounds, and stories of country music with STEAM-powered instructional resources."
                actions={[
                  {
                    asLink: true,
                    noIcon: true,
                    link: '/activities/',
                    label: 'Learn more',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                withBorder={false}
                asCard
                img="img-about-resource-VideoTopicSeries.jpg"
                title="STEAM Country Video Topic Series"
                description="Explore the ways STEAM skills and collaboration contribute to the success of Country Music as students go backstage with industry professionals."
                actions={[
                  {
                    asLink: true,
                    noIcon: true,
                    link: '/STEAMCountry/',
                    label: 'Learn more',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                withBorder={false}
                asCard
                img="img-about-resource-VTF.jpg"
                title="Virtual Field Trip"
                description="Take students behind-the-scenes to learn how the genre of Country is driven by bold ideas and a wide variety of unique careers."
                actions={[
                  {
                    asLink: true,
                    noIcon: true,
                    link: '/virtual-field-trip#vft',
                    label: 'Watch Now',
                  },
                ]}
              />
            </Column>
          </Row>
        </div>
      </Container>
    </Section>
    <Section className="about-partners">
      <Container fullWidth className="about-partners__container">
        <h2 className="about-partners__container__title">About the Partners</h2>
        <div className="about-partners__container__cma">
          <Image
            filename="logo-partner-CMA.svg"
            alt="CMA logo"
            className="about-partners__container__cma__img mb-1"
          />
          <p className="small mb-1">
            Founded in 1958, CMA is the premier trade association of the Country
            Music industry. Representing professionals making a living in
            Country Music globally, the organization serves as a critical
            resource of support and information, honors excellence in the genre
            and provides a forum for industry leadership. CMA is dedicated to
            expanding Country Music around the world through a number of core
            programs and initiatives including the organization's three annual
            television properties&#8212;the CMA Awards, "CMA Fest" and "CMA
            Country Christmas," all of which air on ABC. The organization's
            philanthropic arm, the CMA Foundation, works tirelessly to provide
            equitable access to music education in order to create impactful
            change for students and teachers across the United States.
          </p>
          <OutboundLink
            to="https://CMAworld.com/"
            target="_blank"
            className="about-partners__container__outbound-link text-bold"
          >
            Visit CMAworld.com
            <Icon name="externallink" marginLeft />
          </OutboundLink>
        </div>
        <div className="about-partners__container__cma-foundation">
          <Image
            filename="about-logo-partner-CMA-foundation.svg"
            alt="CMA logo"
            className="mb-1 about-partners__container__cma-foundation__img"
          />
          <p className="small mb-1">
            The Country Music Association established the CMA Foundation, a
            nonprofit 501(c)(3), in 2011 to leverage strategic partnerships,
            professional development for qualified music teachers, and grant
            distribution to improve and withstand equitable music education
            programs for all students nationwide. Headquartered in Nashville,
            Tennessee, the CMA Foundation focuses on providing sustainability,
            advocacy, and accountability within music education by investing in
            various resources for students, schools, and communities.
          </p>
          <OutboundLink
            to="https://CMAFoundation.org/"
            target="_blank"
            className="about-partners__container__outbound-link text-bold"
          >
            Visit CMAFoundation.org
            <Icon name="externallink" marginLeft />
          </OutboundLink>
        </div>
        <div className="about-partners__container__discovery-education">
          <Image
            filename="logo-partner-DE.svg"
            alt="Discovery Education logo"
            className="mb-1 mt-2 about-partners__container__discovery-education__img"
          />
          <p className="small">
            Discovery Education is the global leader in standards-aligned
            digital curriculum resources, engaging content, and professional
            learning for K-12 classrooms. Through its award-winning digital
            textbooks, multimedia resources, and the largest professional
            learning network of its kind, Discovery Education is transforming
            teaching and learning, creating immersive STEM experiences, and
            improving academic achievement around the globe. Discovery Education
            currently serves approximately 4.5 million educators and 45 million
            students worldwide, and its resources are accessed in over 100
            countries and territories. Inspired by the global media company
            Warner Bros. Discovery, Inc., Discovery Education partners with
            districts, states, and like-minded organizations to empower teachers
            with customized solutions that support the success of all learners.
          </p>
          <OutboundLink
            to="https://discoveryeducation.com/"
            target="_blank"
            className="text-bold about-partners__container__outbound-link"
          >
            Visit DiscoveryEducation.com
            <Icon name="externallink" marginLeft />
          </OutboundLink>
        </div>
        <div className="about-partners__container__earbuds">
          <Image
            className="about-partners__container__earbuds__img"
            filename="about-graphic-earbuds.svg"
          />
        </div>
      </Container>
    </Section>
    <Quote
      text="STEAM-related occupations are projected to grow by 8.8% between 2020 and 2028."
      source="U.S. Bureau of Labor Statistics"
      backgroundImage="about-block-quote.png"
    />
  </Layout>
);

export default AboutPage;
